import React from 'react'

export default function Post({ date, title, contents }) {
  return (
    <article className="mt-10">
      <time>{date}</time>
      <h2 className="font-medium text-lg mt-2">{title}</h2>
      <p
        dangerouslySetInnerHTML={{ __html: contents }}
        className="mt-4"
      />
    </article>
  )
}
