import React from 'react'
import Layout from '../components/layout'
import PageTitle from '../components/page-title'
import Post from '../components/post'
import SEO from '../components/seo'

export default function News() {
  const posts = [
    {
      date: '2016.3.31',
      title: 'ホームページを公開しました',
      contents:
        'ホームページを公開いたしました。<br />' +
        '今後もよりいっそう充実したホームページにしていきますので、引き続きよろしくお願いいたします。'
    },
  ]
  return (
    <Layout>
      <SEO title="ニュース | 株式会社サンリク" />
      <PageTitle titleJP="ニュース" titleEN="NEWS" />
      <div className="max-w-screen-lg mx-auto mt-20 px-6 md:px-0">
        {posts.map(post => (
          <Post key={post.date} date={post.date} title={post.title} contents={post.contents} />
        ))}
      </div>
    </Layout>
  )
}
